<script>
import { Toast } from "mint-ui"
import { drawGet } from "@/api/home";
import { appRedPackHistory, appRedPackWithdraws, getLuckyDrawHistory } from "@/api/beijing28";
export default {
    name:'RedEnvelopeHistory',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header: {
                mainTitle: getWord('red_envelope')+'/'+getWord('bonus'),
            },
            type:[
                {
                    title: getWord('red_envelope'),
                    active:true,
                },
                {
                    title: getWord('bonus'),
                    active:false,
                },
            ],
            list:null,
            list2:null,
            success:false,
        };
    },
    methods: {
        filter(item,classes){
            switch(item) {
                case -3:
                    return classes?'gray':getWord('expired')
                    break;
                case -2:
                    return classes?'blue':getWord('inactivated')
                    break;
                case -1:
                    return classes?'red':getWord('inactivation')
                    break;
                case 0:
                    return classes?'green':getWord('withdrawable')
                    break;
                case 1:
                    return classes?'gray':getWord('withdrawn')
                    break;                
                default:
                    break;
            }
        },
        filter2(item,classes){
            switch(item) {
                case 0:
                    return classes?'red':getWord('inactivation')
                    break;
                case 1:
                    return classes?'green':getWord('withdrawable')
                    break;
                case 3:
                    return classes?'gray':getWord('withdrawn')
                    break;
                case -1:
                    return classes?'blue':getWord('inactivated')
                    break;
                default:
                    break;
            }
        },
        withdraw(){
            this.success=false;
            var ids=[];
            this.list.forEach(item=>{
                if (item.status===0) {
                    ids.push(item.id)
                }
            })
            appRedPackWithdraws({
                ids
            }).then(result=>{
                if (result.data.code == "SUCCESS") {
                    this.success=true;
                    // Toast("提取成功");
                    this.$router.go(0)
                } else {
                    Toast(result.data.msg);
                }
            })
        },
        toggleMenu(item){
            this.type.forEach(item=>{
                item.active=false
            })
            item.active=true
        },
        withdraw2(){
            this.success=false;
            drawGet().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.success=true;
                    // Toast("提取成功");
                    this.$router.go(0)
                } else {
                    Toast(result.data.msg);
                }
            });
        },
        close(){
            this.success=false;
        }
    },
    computed:{

    },
    created(){
        
    },
    mounted() {
        if (this.$refs.main) {
            this.CustomScroll(this.$refs.main.id);
        }

        appRedPackHistory().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.list=result.data.result;
            }else {
                Toast(result.data.msg)
            }
        })

        getLuckyDrawHistory().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.list2=result.data.result;
            }else {
                Toast(result.data.msg)
            }
        })
    },
    beforeRouteUpdate(to,from,next){
        
    },
};
</script>

<template>
    <section id="red-envelope" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        />
        <div class="inner">
            <ul class="menu">
                <li v-for="item in type" @click="toggleMenu(item)" :class="{ 'active':item.active }">{{ item.title }}</li>
            </ul>
            <template v-if="type[0].active">
                <table>
                    <tr>
                        <th>{{ getWord('red_envelope_amount') }}</th>
                        <th>{{ getWord('amount_of_betting_required') }}</th>
                        <th>{{ getWord('valid_period') }}</th>
                        <th>{{ getWord('status') }}</th>
                    </tr>
                    <tr v-for="item in list" v-if="list && list.length">
                        <td>{{ item.bonus_money }}</td>
                        <td>{{ Math.ceil(item.bonus_money * item.red_pack.code_amount) }}</td>
                        <td>{{ new Date(item.red_pack.end_time*1000).toLocaleString() }}</td>
                        <td :class="filter(item.status,true)">{{ filter(item.status) }}</td>
                    </tr>
                </table>
                <a class="submit" @click="withdraw()">{{ getWord('red_envelope_tips3') }}</a>
            </template>
            <template v-if="type[1].active">
                <table>
                    <tr>
                        <th>{{ getWord('red_envelope_amount') }}</th>
                        <th>{{ getWord('amount_of_betting_required') }}</th>
                        <th>{{ getWord('valid_period') }}</th>
                        <th>{{ getWord('status') }}</th>
                    </tr>
                    <tr v-for="item in list2" v-if="list2 && list2.length">
                        <td>{{ item.money }}</td>
                        <td>{{ item.consume }}</td>
                        <td>{{ item.time }}</td>
                        <td :class="filter2(item.status,true)">{{ filter2(item.status) }}</td>
                    </tr>
                </table>
                <a class="submit" @click="withdraw2()">{{ getWord('red_envelope_tips8') }}</a>
            </template>
        </div>
        <aside class="popup withdrawn" v-if="success">
            <div class="inner">
                <img :src="require('@@/assets/images/others/icons/red-envelope-popup.png')" />
                <h4>{{ getWord('red_envelope_tips2') }}</h4>
                <a class="button" @click="close()">{{ getWord('ok') }}</a>
                <i class="iconfont2 icon-close2" @click="close()"></i>
            </div>
        </aside>
    </section>
</template>

<style scoped lang='scss'>
@import "@@/assets/style/_variables";
#red-envelope {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {

        table {
            border-collapse: collapse;

            th {
                background-color: #eeeeee;
                color: #b0b0b0;
            }
        }

        .menu {

            li {

                &.active {
                    color: #EC2829;
                    border-bottom-color: #EC2829;
                }
            }
        }

        .submit {
            background-color: #EC2829;
        }

        .popup {

            .button {
                background-color: #EC2829;
            }
        }
    }

    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;        
        overflow: auto;
    }

    .menu {
        display: flex;
        justify-content: space-between;
        list-style: none;
        flex: 0 0 .9rem;

        li {
            font-size: .36rem;
            border-bottom: 2px solid transparent;
            width: 50%;
            text-align: center;
            padding: .2rem 0;

            &.active {
                color: $TEMPLATE2-theme-color;
                border-bottom-color: $TEMPLATE2-theme-color;
            }
        }
    }
    
    table {
        width: 100%;
        flex: 1 1 auto;

        tr {

        }

        th {
            font-size: .28rem;
            color: #B0B0B0;
            width: 25%;
            line-height: 1;
            padding: .15rem 0;
        }

        td {
            padding: .25rem 0;
            font-size: .3rem;
            text-align: center;
            border-top: 1px solid #F2F2F2;

            &.red {
                color: #FF0F0F;
            }

            &.green {
                color: #41A441;
            }

            &.blue {
                color: $TEMPLATE2-theme-color;
            }

            &.gray {
                color: #CCCCCC;
            }
        }        
    }

    .submit {
        display: block;
        margin: auto .25rem .25rem;
        text-align: center;
        padding: .15rem 0;
        color: #ffffff;
        font-size: .36rem;
        background-color: $TEMPLATE2-theme-color;
    }

    .popup {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 11;
        margin: auto;
        bottom: 0;
        width: 5.8rem;
        height: 5.2rem;

        .inner {            
            background-color: #ffffff;
            border-radius: .15rem;
        }

        h4 {
            font-size: .36rem;
            text-align: center;
            margin-top: 1.5rem;

        }

        .button {
            font-size: .34rem;
            background-color: $TEMPLATE2-theme-color;
            color: #ffffff;
            text-align: center;
            position: absolute;
            padding: .25rem .75rem;
            border-radius: .1rem;
            bottom: .5rem;
            left: 50%;
            transform: translateX(-50%);
        }

        .icon-close2 {
            position: absolute;
            right: .25rem;
            top: .25rem;
        }

        img {
            position: absolute;
            top:-1rem;
            width: 1.6rem;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            content:'';
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.8);
            margin: auto;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            position: fixed;
            z-index: -1;
        }
    }
}
</style>